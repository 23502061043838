@media only screen and (max-width: 859px) {
    
    .landing-last-container {
        background-color: #c20f2f;
        position: relative;
        width: 100%;
        margin: 0;
    }
    .landing-last-h2 {
        text-transform: uppercase;
        font-family: Helvetica-condensed;
        font-size: 35px;
        font-weight: lighter;   
        margin: 0 10px 0 auto;
        width: 60%;
        text-align: right;
    }
    .landing-last-main {
        position: relative;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 50px 0 50px 0;
    }
    .landing-image {
        width: 100%;
        height: 100%;
    }
    .landing-div {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .landing-div p {
        margin-top: 8px;
        margin-bottom: 8px;
    }
    .list-icon {
        margin-right: 10px;
    }
    .landing-div:nth-child(4){
        display: none;
    }
    .landing-div:last-child {
        display: none;
    }
    .landing-another-div {
        position: relative;
        color: #fff;
        font-family: Livvic;
        font-size: 16px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 65%;
        margin: auto auto;
        padding-bottom: 25px;
    }

    /********************************************LITINERARY********************************************/

    .first-image {
        display: none;
    }
    .landing-first {
        background-color: #383135;
        position: relative;
        width: 100%;
        margin: 0;
    }
    .landing-first-main {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        left: -48px;
        padding-top: 50px;
    }
    .landing-llave-h2 {
        text-transform: uppercase;
        font-family: Helvetica-condensed;
        font-size: 35px;
        font-weight: lighter;   
        width: 50%;
        margin: auto auto;
    }
    .landing-first-main img {
        margin-right: 10px;
        height: 32px;
    }
    .first-container-content {
        display: flex;
        flex-direction: column-reverse;
    }
    .first-image-mobile {
        width: 100%;
        display: flex;
        align-items: flex-end;
    }
    .img-mobile {
        width: 100%;
    }
    .black-number {
        position: relative;
        width: 19px;
        height: 19px;
        color: #000;
        /*padding: 0px 6px;
        margin: 1px 10px 1px 0px;*/
        top: -17px;
        bottom: 0;
        left: 5px;
        right: 0;
    }
    .number-background {
        position: relative;
        width: 19px;
        height: 19px;
        border-radius: 50%;
        background-color: #33ff99;
        margin: 3px 10px;
    }    
    .first-container-items {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .list-paragraph {
        position: relative;
        font-family: Livvic-semi;
        font-weight: lighter;
        margin: 1px 0px;
    }
    .icon-item {
        position: absolute;
        width: 10%;
        margin-left: 8px;
    }
    .first-container-list {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 70%;
        margin: 45px auto;
    }
    .first-container-list li {
        list-style: none;
    }
    .landing-llave-desktop {
        display: none;
    }
    .form-button {
        color: white !important;

    }
    
    
    
}
@media only screen and (min-width: 540px) and  (max-width: 859px) {
    .landing-another-div {
        position: relative;
        color: #fff;
        font-family: Livvic;
        font-size: 16px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 65%;
        margin: 10px auto auto auto;
        padding-bottom: 25px;

    }
    .landing-last-container {
        background-color: #c20f2f;
        position: relative;
        width: 100%;
        margin: 0;
    }
    .landing-first {
        background-color: #383135;
        position: relative;
        width: 100%;
        /*height: 920px;*/
        margin: 0;
    }
    .first-container-content {
        display: flex;
        flex-direction: column-reverse;
    }
    .first-image-mobile {
        width: 100%;
        display: flex;
        align-items: flex-end;
    }
    .img-mobile {
        width: 100%;
    }
    .form-button {
        color: white !important;

    }

}
/****************************VERSION DESKTOP******************************************************/


@media only screen and (min-width: 860px) {
    /********************** ADITIONAL INFO ************************************/
    .landing-another-div {
        position: relative;
        color: #fff;
        font-family: Livvic;
        font-size: 16px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 65%;
        margin: auto auto;

    }
    .landing-last-container {
        background-color: #c20f2f;
        position: relative;
        width: 100%;
        margin: 0;
        min-height: 75vh;
    }
    .landing-desktop {
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }
    .landing-last-main {
        position: relative;
        display: flex;
        justify-content: flex-end;
        /*align-items: center;*/
    }
    
    .landing-last-h2 {
        text-transform: uppercase;
        font-family: Helvetica-condensed;
        font-size: 46px;
        font-weight: lighter;
        margin-right: 10px;
    }
    .landing-div {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .landing-div p {
        margin-top: 8px;
        margin-bottom: 8px;
    }
    .list-icon {
        margin-right: 10px;
    }
    .landing-div:nth-child(4){
        display: none;
    }
    .landing-div:last-child {
        display: none;
    }

    /*************************************ITINERARY********************************************************/

    .landing-first {
        background-color: #383135;
        position: relative;
        width: 100%;
        margin: 0;
        min-height: 75vh;
    }
    .landing-first-main {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .landing-llave-h2 {
        text-transform: uppercase;
        font-family: Helvetica-condensed;
        font-size: 46px;
        font-weight: lighter;
        margin-left: 10px;
    }
    .landing-llave-left {
        width: 210px;
    }
    .landing-llave-mobile {
        display: none;
    }
    .first-container-content {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 0 0 40px;
        margin: auto 200px;
    }
    .first-image {
        width: 70%;
        margin-right: 35px;
    }
    .first-container-items {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .list-paragraph {
        font-size: 18px;
        position: relative;
        font-family: Livvic-semi;
        margin: 1px 0px;
    }
    .icon-item {
        position: absolute;
        width: 15%;
        margin-left: 8px;
    }
    .first-container-list li {
        list-style: none;
        max-width: 250px;
    }
    .black-number {
        position: relative;
        width: 19px;
        height: 19px;
        color: #000;
        /*padding: 0px 6px;
        margin: 1px 10px 1px 0px;*/
        top: -20px;
        bottom: 0;
        left: 4px;
        right: 0;
    }
    .number-background {
        position: relative;
        width: 19px;
        height: 19px;
        border-radius: 50%;
        background-color: #33ff99;
        margin: 3px 18px 0 10px;
    }
    .first-image-mobile {
        display: none;
    }        
    .form-button {
        color: white !important;

    }
}