@media only screen and (max-width: 859px) {
    body, html {
        margin: 0;
    }
    .mobile-one, .mobile-two, .mobile-three, .mobile-four {
        position: relative;
        /*background-attachment: fixed;*/
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
        min-height: 570px;
    }
    .mobile-one {
        background-image: url(../../Assets/Images/Mobile/01b.png);  
    }
    .mobile-two {
        background-image: url(../../Assets/Images/Mobile/02b.png);       
    }
    .mobile-three {
        background-image: url(../../Assets/Images/Desktop/nueva.jpg);
        background-position: center bottom;
        object-fit: cover; 
        opacity: 85%;
    }
    .mobile-four {
        background-image: url(../../Assets/Images/Mobile/04c.png); 
    }
    .mobile-one::before, .mobile-two::before, .mobile-three::before, .mobile-four::before {
        content: "";
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-color: rgba(243, 89, 89, 0.25);
    }
    .landing-title-desktop{
        width: 105%;
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        left: -130px;
    }
    .landing-title-desktop img {
        height: 32px;
    }
    .title-desktop{
        font-size: 35px;
        margin-top: 32px;
        margin-left: 12px;
    }
    
    .definitivo-container {
        position: relative;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 50px 0 50px 0;
    }
    .definitivo-text {
        text-transform: uppercase;
        font-family: Helvetica-condensed;
        font-size: 35px;
        font-weight: lighter;   
        margin: 0 10px 0 auto;
        width: 60%;
        text-align: right;
    }
    /*.parallax-llave {
        position: absolute;
        right: 0;
        top: 55px;
    }*/
    h2 {
        font-family: Helvetica-condensed;
        font-size: 30px;
        font-weight: lighter;
        color: #FFFFFF;
        margin-top: 50px;
        text-transform: uppercase;
    }
    .desktop-first-text {
        position: absolute;
        width: 85%;
        left: 0;
        margin-left:30px ;
        top: 90px;
        bottom: 0;
    }
    .desktop-one-text {
        position: absolute;
        width: 85%;
        left: 0;
        margin: 40px 30px;
    }
    .desktop-two-text {
        position: absolute;
        width: 85%;
        text-align: right;
        right: 0;
        margin-top: 40px;
        margin-right: 30px;
    }
    .principal-escritorio{
        position: absolute;
        width: 85%;
        text-align: right;
        right: 0;
        margin-top: 30px;
        margin-left: 40px;
    }
    p {
        font-family: Livvic-semi;
        font-size: 16px;
        font-weight: bold;
        color: #FFFFFF;
    }
    .first-para {
        font-family: Livvic-semi;
        font-size: 16px;
        margin-top: 30px;
    }
    .first-para:last-child {
        font-size: 16px;
        margin-top: 40px;
    }
    b {
        font-family: Livvic-semi;
        font-weight: bolder;
    }
    .title-llave {
        display: flex;
        align-items: baseline;
    }
    .circulo {
        width: 32px;
        height: 32px;
    }
    
    h1 {
        position: absolute;
        bottom: -17%;
        width: 100%;
        text-align: center;
    }
    .parallax-second {
        margin: 8px 0;
    }

   
    .parallax-three {
        width: 65%;
        margin-top: 0;
        margin-bottom: 0;
    }
    .menu {
        display: flex;
        justify-content: flex-end;
    }
    .menu-three {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
    }
    .menu-four {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
    }
    .parallax-four {
        font-size: 28px;
        margin-top: 2px;
        margin-bottom: 2px;
        
    }
    
    .parallax-desktop {
        display: none;
    }
    .menu-icon {
        margin-right: 5px;
        /*animation-name: fadeIcon;
        animation-duration: 1s;
        animation-timing-function: linear;*/
        animation-name: floating;
        animation-duration: 2s;
        animation-timing-function: ease;
        animation-iteration-count: infinite;
    }
    @keyframes floating {
        0% {
            transform: translate(0%, 0%) rotate(360deg);
        }
        25% {
            transform: translate(5%, 15%) rotate(360deg);
        }
        50% {
            transform: translate(10%, 5%) rotate(360deg);
        }
        75% {
            transform: translate(0%, 15%) rotate(360deg);
        }
        100% {
            transform: translate(0%, 0%) rotate(360deg);
        }
    }
    
    
} 

@media only screen and (min-width: 860px) {
    body, html {
        margin: 0;
    }
    .parallax-mobile {
        display: none;
    }
    
    .desktop-one, .desktop-two, .desktop-three, .desktop-four {
        position: relative;
        background-attachment: fixed;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
    }
    .desktop-one {
        background-image: url(../../Assets/Images/Desktop/01.png);
        min-height: 75vh;
        
    }
    .desktop-two {
        background-image: url(../../Assets/Images/Desktop/02.png);
        min-height: 75vh;
        
    }
    .desktop-three::before {
        content: "";
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-color: rgba(243, 89, 89, 0.25);
    }
    .desktop-three {
        background-image: url(../../Assets/Images/Desktop/03b.jpg);
        min-height: 75vh;
        opacity: 85%;
    }
    .desktop-four {
        background-image: url(../../Assets/Images/Desktop/04.png);
        min-height: 75vh;
    }
    .desktop-one-text {
        position: absolute;
        width: 46%;
        left: 0;
        margin: 70px 210px;
    }
    .desktop-first-text {
        position: absolute;
        width: 40%;
        left: 0;
        margin: auto 210px;
    }
    .desktop-two-text {
        position: absolute;
        width: 36%;
        text-align: right;
        right: 0;
        margin: 0 210px;
    }
    .desktop-four-text {
        position: absolute;
        width: 36%;
        text-align: right;
        right: 0;
        margin: 70px 210px;
    }
    h2, h1 { 
        font-family: Helvetica-condensed;
        text-transform: uppercase;
        font-size: 40px;
        font-weight: lighter;
        color: #FFFFFF;
    }
    p {
        font-family: Livvic-semi;
        font-size: 18px;
        font-weight: bold;
        color: #FFFFFF;
    }
    .first-para {
        font-family: Livvic-semi;
        font-size: 18px;
        margin-top: 0;
        /*margin: 20px 0;*/
        /*font-weight: normal;*/
    }
    .first-para:last-child {
        font-family: Livvic-semi;
        font-size: 18px;
        margin-top: 0;
        margin: 30px 0;
        /*font-weight: normal;*/
    }
    b {
        font-family: Livvic-semi;
        font-weight: bolder;
    }
    .parallax-second {
        margin: 8px 0;
    }
    .custom-margin{
        margin-top: 50px;
        font-size: 14px;
    }
    .parallax-three {
        margin-bottom: 8px;
    }
    .parallax-four {
        margin-bottom: 8px;
    }
    .menu, .menu-four {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
    }
    .menu-three {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    /*.landing-llave {
        width: 210px;
        height: 32px;
        
    }*/
    .parallax-image-container-desktop {
        position: relative;
        display: flex;
        justify-content: flex-end;
        /*align-items: center;*/
    }
    .parallax-image {
        width: 100%;
        height: 150%;
        bottom: 30px;
        position: relative;
    }
    .h1 {
        text-transform: uppercase;
        font-family: Helvetica-condensed;
        font-size: 46px;
        font-weight: lighter;
        margin-right: 10px;
    }
    /*.parallax-llave {
        width: 272px;
    }*/
    .menu-icon {
        margin: 0 15px;
        /*animation-name: fadeIcon;
        animation-duration: 1s;
        animation-timing-function: linear;*/
        animation-name: floating;
        animation-duration: 2s;
        animation-timing-function: ease;
        animation-iteration-count: infinite;
    }
    /*@keyframes fadeIcon {
        from {
            opacity: 0;
            transform: translateY(-20px);
        }
        to {
            opacity: 0;
            transform: translateY(0);
        }
    }*/
    @keyframes floating {
        0% {
            transform: translate(0%, 0%) rotate(360deg);
        }
        25% {
            transform: translate(5%, 15%) rotate(360deg);
        }
        50% {
            transform: translate(10%, 5%) rotate(360deg);
        }
        75% {
            transform: translate(0%, 15%) rotate(360deg);
        }
        100% {
            transform: translate(0%, 0%) rotate(360deg);
        }
    }
    

    .landing-title-desktop{
        display: flex;
        align-items: center;        
    }
    .title-desktop{
        font-size: 46px;
        margin-left: 12px;
    }
    
}